import { extendTheme } from '@chakra-ui/react'
import '@fontsource/judson/400.css'
import '@fontsource/judson/700.css'
import '@fontsource-variable/dm-sans'
import { accordionAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

// default base style from the Input theme
const baseStyle = definePartsStyle({
  container: defineStyle({
    boxShadow: 'sm',
    _focus: {
      boxShadow: 'outline',
    },
  }),
})

// Defining a custom variant called outline
const outline = definePartsStyle(() => {
  return {
    container: {
      border: '1px solid #f1e9e7',
      borderRadius: '16px',
      bg: 'white',
      p: 6,
      my: 4,
    },
    button: {
      px: 0,
      fontFamily: `'Judson', sans-serif`,
      fontSize: {
        base: '20px',
        md: '24px',
      },
      fontWeight: 700,
      color: 'primary.100',
      bg: 'white',
      _hover: {
        color: 'primary.100',
        bg: 'white',
      },
      _focus: {
        color: 'primary.100',
        bg: 'white',
      },
      _active: {
        color: 'primary.100',
        bg: 'white',
      },
      _highlighted: {
        color: 'primary.100',
        bg: 'white',
      },
      _groupFocus: {
        color: 'primary.100',
        bg: 'white',
      },
    },
    panel: {
      fontSize: {
        base: '14px',
        md: '16px',
      },
      px: 0,
      pt: 2,
    },
  }
})

const variants = {
  outline,
}

const size = {
  md: defineStyle({
    w: 5,
    h: 5,
  }),
}

const sizes = {
  md: definePartsStyle({
    icon: size.md,
  }),
}

const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
})

// This should be moved up in the order
const theme = {
  colors: {
    white: '#FEFEFE',
    backgroundLight: '#FCF9F4',
    primary: {
      50: '#f1e9e7',
      100: '#AD8A79',
      200: '#C89F8C',
      300: '#EED3C0',
      400: '#ECA998',
    },
    button: {
      50: '#f1e9e7',
      100: '#AD8A79',
      200: '#AD8A79',
      300: '#AD8A79',
      400: '#AD8A79',
      500: '#C89F8C',
      600: '#AD8A79',
      700: '#AD8A79',
      800: '#AD8A79',
      900: '#AD8A79',
    },
    accent: '#FCF9F4CC',
    red: '#EB5757',
    dark: '#13171D',
    lightBorder: '#DBDDD8',
    gray: {
      50: '#B6B4B1',
      100: '#71706E',
      400: '#434443',
      900: '#13171D',
    },
  },
  components: {
    Accordion: accordionTheme,
  },
  boxShadow: '0px 2px 3px 0px #C7A99B33',
  fonts: {
    heading: `'DM Sans Variable', sans-serif`,
    body: `'DM Sans Variable', sans-serif`,
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      body: {
        bg: 'primary.50',
        color: 'dark',
      },
    },
  },
}

export default extendTheme(theme)
